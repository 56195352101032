
<template>
    <div style="width: 80%;margin: auto">
        <div style="margin: 50px 0 20px;">
            <div style="display: inline-block;min-width: 500px;margin-right: 50px"><el-input v-model="title" placeholder="请输入标题"></el-input></div>

            <div class="block" style="display: inline-block; margin-right: 50px">
                <el-cascader
                    v-model="article_type"
                    :options="options"
                    :props="props"></el-cascader>
            </div>

            <div style="display: inline-block; margin-right: 50px"><el-input v-model="pwd" placeholder="请输入密匙"></el-input></div>

            <el-button @click="publiClick">发布</el-button>

        </div>


        <v-md-editor
            v-model="md_content"
            :left-toolbar="leftToolbar"
            :include-level="[1, 2]"
            height="733px"
            :disabled-menus="[]"
            @upload-image="handleUploadImage"
            :toolbar-config="toolbarConfig"
        ></v-md-editor>
    </div>

</template>

<script>
import VueMarkdownEditor, { xss } from '@kangc/v-md-editor';
import {ElMessage} from "element-plus";

export default {

    name: "PublicArticle",
    components: { },
    data() {
        return {
            md_content: '',
            leftToolbar: 'undo redo clear | h bold italic strikethrough quote | ul ol todo-list table hr | link image code | tip emoji ',
            toolbarConfig: {
                'image-link': {
                    // 是否在插入图片链接的时候默认带上width height 属性，默认值：false
                    insertWithSize: true
                }
            },

            title: '',
            article_type: '',
            pwd: '',
            options: [],
            props: { expandTrigger: 'hover' },
        }
    },
    methods: {
        async handleUploadImage(event, insertImage, files) {
            var configs = {
                headers:{'Content-Type':'multipart/form-data'}
            };

            var that = this;
            let formData=new FormData();
            formData.append('article_img',files[0])


            var file_path;
            await that.axios.post('/upload_img', formData, configs)
                .then(function (response) {
                    file_path = response.data.file_path;
                })


            // 拿到 files 之后上传到文件服务器，然后向编辑框中插入对应的内容
            // 此处只做示例
            insertImage({
                url: file_path,
                desc: '',
                width: '300px',
            });
        },



        publiClick: function () {
            let formData = new FormData();
            const html = xss.process(VueMarkdownEditor.vMdParser.themeConfig.markdownParser.render(this.md_content));
            formData.append('title',this.title)
            if (this.article_type[1]){
                formData.append('article_type',this.article_type[1])
            }

            formData.append('pwd',this.pwd)
            formData.append('content',this.md_content)

            var that = this;
            that.axios.post('/public_article', formData)
                .then(function (response) {
                    if (response.data.code) {
                        ElMessage({
                            showClose: true,
                            message: response.data.msg,
                            type: 'success'
                        });
                        that.title = '';
                        that.article_type = '';
                        that.pwd = '';
                        that.md_content = '';
                    }else{
                        ElMessage({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                    }
                }).catch(function (error) {
                    ElMessage({
                        showClose: true,
                        message: '发布失败',
                        type: 'error'
                    });
            })

        },



    },

    created() {
          var that = this;
          that.axios.get('/all_type')
              .then(function (response) {
                  that.options = response.data.data_list;
              })
              .catch(function (error) {

              })
    },

};
</script>
